const US_STATES = [
  {code: 'AL', name: 'Alabama'},
  {code: 'AK', name: 'Alaska'},
  {code: 'AZ', name: 'Arizona'},
  {code: 'AR', name: 'Arkansas'},
  {code: 'CA', name: 'California'},
  {code: 'CO', name: 'Colorado'},
  {code: 'CT', name: 'Connecticut'},
  {code: 'DE', name: 'Delaware'},
  {code: 'FL', name: 'Florida'},
  {code: 'GA', name: 'Georgia'},
  {code: 'HI', name: 'Hawaii'},
  {code: 'ID', name: 'Idaho'},
  {code: 'IL', name: 'Illinois'},
  {code: 'IN', name: 'Indiana'},
  {code: 'IA', name: 'Iowa'},
  {code: 'KS', name: 'Kansas'},
  {code: 'KY', name: 'Kentucky'},
  {code: 'LA', name: 'Louisiana'},
  {code: 'ME', name: 'Maine'},
  {code: 'MD', name: 'Maryland'},
  {code: 'MA', name: 'Massachusetts'},
  {code: 'MI', name: 'Michigan'},
  {code: 'MN', name: 'Minnesota'},
  {code: 'MS', name: 'Mississippi'},
  {code: 'MO', name: 'Missouri'},
  {code: 'MT', name: 'Montana'},
  {code: 'NE', name: 'Nebraska'},
  {code: 'NV', name: 'Nevada'},
  {code: 'NH', name: 'New Hampshire'},
  {code: 'NJ', name: 'New Jersey'},
  {code: 'NM', name: 'New Mexico'},
  {code: 'NY', name: 'New York'},
  {code: 'NC', name: 'North Carolina'},
  {code: 'ND', name: 'North Dakota'},
  {code: 'OH', name: 'Ohio'},
  {code: 'OK', name: 'Oklahoma'},
  {code: 'OR', name: 'Oregon'},
  {code: 'PA', name: 'Pennsylvania'},
  {code: 'RI', name: 'Rhode Island'},
  {code: 'SC', name: 'South Carolina'},
  {code: 'SD', name: 'South Dakota'},
  {code: 'TN', name: 'Tennessee'},
  {code: 'TX', name: 'Texas'},
  {code: 'UT', name: 'Utah'},
  {code: 'VT', name: 'Vermont'},
  {code: 'VA', name: 'Virginia'},
  {code: 'WA', name: 'Washington'},
  {code: 'WV', name: 'West Virginia'},
  {code: 'WI', name: 'Wisconsin'},
  {code: 'WY', name: 'Wyoming'},
];

const CANADA_STATES = [
  {code: 'AB', name: 'Alberta'},
  {code: 'BC', name: 'British Columbia'},
  {code: 'MB', name: 'Manitoba'},
  {code: 'NB', name: 'New Brunswick'},
  {code: 'NL', name: 'Newfoundland and Labrador'},
  {code: 'NT', name: 'Northwest Territories'},
  {code: 'NS', name: 'Nova Scotia'},
  {code: 'NU', name: 'Nunavut'},
  {code: 'ON', name: 'Ontario'},
  {code: 'PE', name: 'Prince Edward Island'},
  {code: 'QC', name: 'Quebec'},
  {code: 'SK', name: 'Saskatchewan'},
  {code: 'YT', name: 'Yukon'}
];

const UK_STATES = [
  {code: 'LND', name: 'London, City of'},
  {code: 'ABE', name: 'Aberdeen City'},
  {code: 'ABD', name: 'Aberdeenshire'},
  {code: 'ANS', name: 'Angus'},
  {code: 'AGB', name: 'Argyll and Bute'},
  {code: 'CLK', name: 'Clackmannanshire'},
  {code: 'DGY', name: 'Dumfries and Galloway'},
  {code: 'DND', name: 'Dundee City'},
  {code: 'EAY', name: 'East Ayrshire'},
  {code: 'EDU', name: 'East Dunbartonshire'},
  {code: 'ELN', name: 'East Lothian'},
  {code: 'ERW', name: 'East Renfrewshire'},
  {code: 'EDH', name: 'Edinburgh, City of'},
  {code: 'ELS', name: 'Eilean Siar'},
  {code: 'FAL', name: 'Falkirk'},
  {code: 'FIF', name: 'Fife'},
  {code: 'GLG', name: 'Glasgow City'},
  {code: 'HLD', name: 'Highland'},
  {code: 'IVC', name: 'Inverclyde'},
  {code: 'MLN', name: 'Midlothian'},
  {code: 'MRY', name: 'Moray'},
  {code: 'NAY', name: 'North Ayrshire'},
  {code: 'NLK', name: 'North Lanarkshire'},
  {code: 'ORK', name: 'Orkney Islands'},
  {code: 'PKN', name: 'Perth and Kinross'},
  {code: 'RFW', name: 'Renfrewshire'},
  {code: 'SCB', name: 'Scottish Borders'},
  {code: 'ZET', name: 'Shetland Islands'},
  {code: 'SAY', name: 'South Ayrshire'},
  {code: 'SLK', name: 'South Lanarkshire'},
  {code: 'STG', name: 'Stirling'},
  {code: 'WDU', name: 'West Dunbartonshire'},
  {code: 'WLN', name: 'West Lothian'},
  {code: 'ANN', name: 'Antrim and Newtownabbey'},
  {code: 'AND', name: 'Ards and North Down'},
  {code: 'ABC', name: 'Armagh City, Banbridge and Craigavon'},
  {code: 'BFS', name: 'Belfast City'},
  {code: 'CCG', name: 'Causeway Coast and Glens'},
  {code: 'DRS', name: 'Derry and Strabane'},
  {code: 'FMO', name: 'Fermanagh and Omagh'},
  {code: 'LBC', name: 'Lisburn and Castlereagh'},
  {code: 'MEA', name: 'Mid and East Antrim'},
  {code: 'MUL', name: 'Mid-Ulster'},
  {code: 'NMD', name: 'Newry, Mourne and Down'},
  {code: 'BDG', name: 'Barking and Dagenham'},
  {code: 'BNE', name: 'Barnet'},
  {code: 'BEX', name: 'Bexley'},
  {code: 'BEN', name: 'Brent'},
  {code: 'BRY', name: 'Bromley'},
  {code: 'CMD', name: 'Camden'},
  {code: 'CRY', name: 'Croydon'},
  {code: 'EAL', name: 'Ealing'},
  {code: 'ENF', name: 'Enfield'},
  {code: 'GRE', name: 'Greenwich'},
  {code: 'HCK', name: 'Hackney'},
  {code: 'HMF', name: 'Hammersmith and Fulham'},
  {code: 'HRY', name: 'Haringey'},
  {code: 'HRW', name: 'Harrow'},
  {code: 'HAV', name: 'Havering'},
  {code: 'HIL', name: 'Hillingdon'},
  {code: 'HNS', name: 'Hounslow'},
  {code: 'ISL', name: 'Islington'},
  {code: 'KEC', name: 'Kensington and Chelsea'},
  {code: 'KTT', name: 'Kingston upon Thames'},
  {code: 'LBH', name: 'Lambeth'},
  {code: 'LEW', name: 'Lewisham'},
  {code: 'MRT', name: 'Merton'},
  {code: 'NWM', name: 'Newham'},
  {code: 'RDB', name: 'Redbridge'},
  {code: 'RIC', name: 'Richmond upon Thames'},
  {code: 'SWK', name: 'Southwark'},
  {code: 'STN', name: 'Sutton'},
  {code: 'TWH', name: 'Tower Hamlets'},
  {code: 'WFT', name: 'Waltham Forest'},
  {code: 'WND', name: 'Wandsworth'},
  {code: 'WSM', name: 'Westminster'},
  {code: 'BNS', name: 'Barnsley'},
  {code: 'BIR', name: 'Birmingham'},
  {code: 'BOL', name: 'Bolton'},
  {code: 'BRD', name: 'Bradford'},
  {code: 'BUR', name: 'Bury'},
  {code: 'CLD', name: 'Calderdale'},
  {code: 'COV', name: 'Coventry'},
  {code: 'DNC', name: 'Doncaster'},
  {code: 'DUD', name: 'Dudley'},
  {code: 'GAT', name: 'Gateshead'},
  {code: 'KIR', name: 'Kirklees'},
  {code: 'KWL', name: 'Knowsley'},
  {code: 'LDS', name: 'Leeds'},
  {code: 'LIV', name: 'Liverpool'},
  {code: 'MAN', name: 'Manchester'},
  {code: 'NET', name: 'Newcastle upon Tyne'},
  {code: 'NTY', name: 'North Tyneside'},
  {code: 'OLD', name: 'Oldham'},
  {code: 'RCH', name: 'Rochdale'},
  {code: 'ROT', name: 'Rotherham'},
  {code: 'SLF', name: 'Salford'},
  {code: 'SAW', name: 'Sandwell'},
  {code: 'SFT', name: 'Sefton'},
  {code: 'SHF', name: 'Sheffield'},
  {code: 'SOL', name: 'Solihull'},
  {code: 'STY', name: 'South Tyneside'},
  {code: 'SHN', name: 'St. Helens'},
  {code: 'SKP', name: 'Stockport'},
  {code: 'SND', name: 'Sunderland'},
  {code: 'TAM', name: 'Tameside'},
  {code: 'TRF', name: 'Trafford'},
  {code: 'WKF', name: 'Wakefield'},
  {code: 'WLL', name: 'Walsall'},
  {code: 'WGN', name: 'Wigan'},
  {code: 'WRL', name: 'Wirral'},
  {code: 'WLV', name: 'Wolverhampton'},
  {code: 'BKM', name: 'Buckinghamshire'},
  {code: 'CAM', name: 'Cambridgeshire'},
  {code: 'CMA', name: 'Cumbria'},
  {code: 'DBY', name: 'Derbyshire'},
  {code: 'DEV', name: 'Devon'},
  {code: 'DOR', name: 'Dorset'},
  {code: 'ESX', name: 'East Sussex'},
  {code: 'ESS', name: 'Essex'},
  {code: 'GLS', name: 'Gloucestershire'},
  {code: 'HAM', name: 'Hampshire'},
  {code: 'HRT', name: 'Hertfordshire'},
  {code: 'KEN', name: 'Kent'},
  {code: 'LAN', name: 'Lancashire'},
  {code: 'LEC', name: 'Leicestershire'},
  {code: 'LIN', name: 'Lincolnshire'},
  {code: 'NFK', name: 'Norfolk'},
  {code: 'NYK', name: 'North Yorkshire'},
  {code: 'NTH', name: 'Northamptonshire'},
  {code: 'NTT', name: 'Nottinghamshire'},
  {code: 'OXF', name: 'Oxfordshire'},
  {code: 'SOM', name: 'Somerset'},
  {code: 'STS', name: 'Staffordshire'},
  {code: 'SFK', name: 'Suffolk'},
  {code: 'SRY', name: 'Surrey'},
  {code: 'WAR', name: 'Warwickshire'},
  {code: 'WSX', name: 'West Sussex'},
  {code: 'WOR', name: 'Worcestershire'},
  {code: 'BAS', name: 'Bath and North East Somerset'},
  {code: 'BDF', name: 'Bedford'},
  {code: 'BBD', name: 'Blackburn with Darwen'},
  {code: 'BPL', name: 'Blackpool'},
  {code: 'BGW', name: 'Blaenau Gwent'},
  {code: 'BCP', name: 'Bournemouth, Christchurch and Poole'},
  {code: 'BRC', name: 'Bracknell Forest'},
  {code: 'BGE', name: 'Bridgend [Pen-y-bont ar Ogwr GB-POG]'},
  {code: 'BNH', name: 'Brighton and Hove'},
  {code: 'BST', name: 'Bristol, City of'},
  {code: 'CAY', name: 'Caerphilly [Caerffili GB-CAF]'},
  {code: 'CRF', name: 'Cardiff [Caerdydd GB-CRD]'},
  {code: 'CMN', name: 'Carmarthenshire [Sir Gaerfyrddin GB-GFY]'},
  {code: 'CBF', name: 'Central Bedfordshire'},
  {code: 'CGN', name: 'Ceredigion [Sir Ceredigion]'},
  {code: 'CHE', name: 'Cheshire East'},
  {code: 'CHW', name: 'Cheshire West and Chester'},
  {code: 'CWY', name: 'Conwy'},
  {code: 'CON', name: 'Cornwall'},
  {code: 'DAL', name: 'Darlington'},
  {code: 'DEN', name: 'Denbighshire [Sir Ddinbych GB-DDB]'},
  {code: 'DER', name: 'Derby'},
  {code: 'DUR', name: 'Durham, County'},
  {code: 'ERY', name: 'East Riding of Yorkshire'},
  {code: 'FLN', name: 'Flintshire [Sir y Fflint GB-FFL]'},
  {code: 'GWN', name: 'Gwynedd'},
  {code: 'HAL', name: 'Halton'},
  {code: 'HPL', name: 'Hartlepool'},
  {code: 'HEF', name: 'Herefordshire'},
  {code: 'AGY', name: 'Isle of Anglesey [Sir Ynys Môn GB-YNM]'},
  {code: 'IOW', name: 'Isle of Wight'},
  {code: 'IOS', name: 'Isles of Scilly'},
  {code: 'KHL', name: 'Kingston upon Hull'},
  {code: 'LCE', name: 'Leicester'},
  {code: 'LUT', name: 'Luton'},
  {code: 'MDW', name: 'Medway'},
  {code: 'MTY', name: 'Merthyr Tydfil [Merthyr Tudful GB-MTU]'},
  {code: 'MDB', name: 'Middlesbrough'},
  {code: 'MIK', name: 'Milton Keynes'},
  {code: 'MON', name: 'Monmouthshire [Sir Fynwy GB-FYN]'},
  {code: 'NTL', name: 'Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]'},
  {code: 'NWP', name: 'Newport [Casnewydd GB-CNW]'},
  {code: 'NEL', name: 'North East Lincolnshire'},
  {code: 'NLN', name: 'North Lincolnshire'},
  {code: 'NSM', name: 'North Somerset'},
  {code: 'NBL', name: 'Northumberland'},
  {code: 'NGM', name: 'Nottingham'},
  {code: 'PEM', name: 'Pembrokeshire [Sir Benfro GB-BNF]'},
  {code: 'PTE', name: 'Peterborough'},
  {code: 'PLY', name: 'Plymouth'},
  {code: 'POR', name: 'Portsmouth'},
  {code: 'POW', name: 'Powys'},
  {code: 'RDG', name: 'Reading'},
  {code: 'RCC', name: 'Redcar and Cleveland'},
  {code: 'RCT', name: 'Rhondda Cynon Taff [Rhondda CynonTaf]'},
  {code: 'RUT', name: 'Rutland'},
  {code: 'SHR', name: 'Shropshire'},
  {code: 'SLG', name: 'Slough'},
  {code: 'SGC', name: 'South Gloucestershire'},
  {code: 'STH', name: 'Southampton'},
  {code: 'SOS', name: 'Southend-on-Sea'},
  {code: 'STT', name: 'Stockton-on-Tees'},
  {code: 'STE', name: 'Stoke-on-Trent'},
  {code: 'SWA', name: 'Swansea [Abertawe GB-ATA]'},
  {code: 'SWD', name: 'Swindon'},
  {code: 'TFW', name: 'Telford and Wrekin'},
  {code: 'THR', name: 'Thurrock'},
  {code: 'TOB', name: 'Torbay'},
  {code: 'TOF', name: 'Torfaen [Tor-faen]'},
  {code: 'VGL', name: 'Vale of Glamorgan, The [Bro Morgannwg GB-BMG]'},
  {code: 'WRT', name: 'Warrington'},
  {code: 'WBK', name: 'West Berkshire'},
  {code: 'WIL', name: 'Wiltshire'},
  {code: 'WNM', name: 'Windsor and Maidenhead'},
  {code: 'WOK', name: 'Wokingham'},
  {code: 'WRX', name: 'Wrexham [Wrecsam GB-WRC]'},
  {code: 'YOR', name: 'York'}
];

const IRELAND_STATES = [
  {code: 'C', name: 'Connacht'},
  {code: 'CW', name: 'County Carlow'},
  {code: 'CN', name: 'County Cavan'},
  {code: 'CE', name: 'County Clare'},
  {code: 'CO', name: 'County Cork'},
  {code: 'DL', name: 'County Donegal'},
  {code: 'D', name: 'County Dublin'},
  {code: 'G', name: 'County Galway'},
  {code: 'KY', name: 'County Kerry'},
  {code: 'KE', name: 'County Kildare'},
  {code: 'KK', name: 'County Kilkenny'},
  {code: 'LS', name: 'County Laois'},
  {code: 'LK', name: 'County Limerick'},
  {code: 'LD', name: 'County Longford'},
  {code: 'LH', name: 'County Louth'},
  {code: 'MO', name: 'County Mayo'},
  {code: 'MH', name: 'County Meath'},
  {code: 'MN', name: 'County Monaghan'},
  {code: 'OY', name: 'County Offaly'},
  {code: 'RN', name: 'County Roscommon'},
  {code: 'SO', name: 'County Sligo'},
  {code: 'TA', name: 'County Tipperary'},
  {code: 'WD', name: 'County Waterford'},
  {code: 'WH', name: 'County Westmeath'},
  {code: 'WX', name: 'County Wexford'},
  {code: 'WW', name: 'County Wicklow'},
  {code: 'L', name: 'Leinster'},
  {code: 'M', name: 'Munster'},
  {code: 'U', name: 'Ulster'}
];

module.exports = {
  US_STATES,
  CANADA_STATES,
  UK_STATES,
  IRELAND_STATES
};
